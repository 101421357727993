import dashboardIcon from './dashboard.svg';
import userIcon from "./user-man.svg"; 
import userProfile from './default-welcm-img.png'
import downArrow from './drparrow.svg'
import burgerIcon from './burger-icon.svg'
import PaymentIcon from './pngwing.png';
import finance from './undraw_finance_re_gnv2.svg'
import logo from './ufone-4g-logo.png'

import youtube from './social/youtube.svg'
import facebook from './social/facebook.svg'
import instgram from './social/instgram.svg'
import twitter from './social/twitter.svg'

const menuIcon = burgerIcon;

export {
  dashboardIcon,
  userIcon, 
  menuIcon,
  userProfile,
  downArrow,
  PaymentIcon,
  finance,
  logo,
  youtube,facebook,instgram,twitter
}
