import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    display: 'flex',
    height:500,
    backgroundColor:"#f00"
  },
  paper: {
    margin: theme.spacing(2, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  submit: {
    margin: theme.spacing(3, 8, 2),
  }
}));
