import React from 'react'; 
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';  
import Link from '@material-ui/core/Link';
import {useStyles} from "./styles";
import {generalStyles} from "../general/general";
import {logo} from "../../../assets/images/img/index"; 
import {youtube} from "../../../assets/images/img/index"; 
import {facebook} from "../../../assets/images/img/index"; 
import {instgram} from "../../../assets/images/img/index"; 
import {twitter} from "../../../assets/images/img/index"; 
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'; 
import ListItemText from '@material-ui/core/ListItemText';  
import MenuIcon from '@material-ui/icons/Menu'; 
import {banner1,banner2} from "../../../assets/images/banner/index"; 
export default function Header() {
  const classes = useStyles();
  const generalClasses = generalStyles();
  const anchor="Left";
  const [drawer, setDrawer] = React.useState({ anchor: false});

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  const headerLinks = [{ 
      title: "Products", 
      link:"https://www.ufone.com/", 
    },{ 
      title: "Support", 
      link:"https://www.ufone.com/support", 
    },{ 
      title: "My Ufone", 
      link:"https://www.ufone.com/selfcare/app/login/login.php", 
    } ,{ 
      title: "Online Recharge", 
      link:"https://www.ufone.com/selfcare/app/recharge/recharge.php", 
    } ,{ 
      title: "Buy SIM", 
      link:"https://www.ufone.com/selfcare/app/buy-sim/buysim.php", 
    } ,{ 
      title: "Complaints", 
      link:"https://www.ufone.com/support", 
    } ,{ 
      title: "UPaisa", 
      link:"https://www.upaisa.com/", 
    }  
  ];

  const list = () => (
    <div className={clsx(classes.list, { [classes.fullList]: anchor === 'top' || anchor === 'bottom',  })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {headerLinks.map((item, index) => (
          <ListItem button key={index}>  
            <ListItemText onClick={() => openInNewTab(item.link)} primary={item.title} />
          </ListItem>
        ))}
      </List> 
    </div>
  );
  const openInNewTab = url => { 
    window.open(url, '_blank', 'noopener,noreferrer');
  };
 
  return (
    
    <div className={generalClasses.root}> 
      <div>
        <Box sx={{ display: { xs: 'none', sm:'none', md:'block' } }}>
            <div className={classes.topHeader}>
              <Container fixed>
                <div className={classes.topHeaderInner}>
                  <Link onClick={() => openInNewTab('https://www.ufone.com/')} component="button" variant="body2">
                      Personal
                  </Link>
                  <Link onClick={() => openInNewTab('https://www.ufone.com/business')} target="_blank" component="button" variant="body2">
                      Business
                  </Link> 
                </div>
              </Container>
            </div>
          </Box>
      </div>
          
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
        
          <Container fixed>
            
            <div className={generalClasses.displayFlex}> 
              <Box sx={{ display: { xs: 'block', md:'none' } }}> 
                <IconButton onClick={toggleDrawer(true)}
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer" 
                  >
                  <MenuIcon />
                </IconButton>
                
                <SwipeableDrawer 
                  open={drawer[anchor]}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                >
                  {list()}
                </SwipeableDrawer> 
              </Box>
              <div className={classes.logo}>
                <a href="/">
                  <img src={logo} alt="logo"  />
                </a>
              </div>
              <Box sx={{ display: { xs: 'none', md:'block' } }} > 
                <div className={classes.headerNav}>
                  {headerLinks.map((item, index) => (
                    <Link onClick={() => openInNewTab(item.link)} key={index} component="button" variant="body2">
                      {item.title}
                    </Link>
                  ))} 
                </div> 
              </Box>
              <Box sx={{ display: { xs: 'none', md:'block' } }}  className={classes.socialIcons}> 
                <div>
                  <img src={youtube} alt="youtube"  />
                  <img src={facebook} alt="facebook"  />
                  <img src={instgram} alt="instgram"  />
                  <img src={twitter} alt="twitter"  />
                </div>
              </Box>
            </div>
          </Container>
        </Toolbar>
      </AppBar> 
      <div className={`${generalClasses.marBottom40} ${generalClasses.posRelative}`}>
        <Box sx={{ display: { xs: 'none', md:'block' } }} > 
            <div>
                <img src={banner1} alt="banner"  className={generalClasses.fullWidth}/>
            </div> 
        </Box>
        <Box sx={{ display: { xs: 'block', md:'none' } }} > 
            <div>
                <img src={banner2} alt="banner" className={generalClasses.fullWidth} />
            </div> 
        </Box>
        <span  className={classes.playIconBann}></span>
      </div>
    </div>
  );
}
