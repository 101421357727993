import * as actionTypes from "../actionTypes";

export const getPaymentData = (data) => { 
    return {
        type: actionTypes.GET_PAYMENT_DATA_START,
        payload: data,
    };
};
export const getPaymentDataSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_DATA_SUCCESS,
        payload: data.data,
    };
};
export const getPaymentDataFailure = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_DATA_FAILURE,
        payload: data,
    };
};

export const messageHandler = () => { 
    return {
        type: actionTypes.MESSAGE_HANDLER,
        payload: null,
    };
};
