import { alpha, makeStyles } from '@material-ui/core/styles';
import {playBan} from "../../../assets/images/images"; 
import {
  linkIcon1, linkIcon2, linkIcon3, linkIcon4, linkIcon5,
  linkIconHover1, linkIconHover2, linkIconHover3, linkIconHover4, linkIconHover5
} from '../../../assets/images/images';
export const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 160,
  },
  topHeader:{
    width: '100%',
    backgroundColor:"#ef7a15",
    color:"#fff",
    "& button": {
      padding:'10px',
      fontSize:"12",
      color:'#fff'
    }
  },
  footer:{
    width: '100%',
    backgroundColor:"#ef7a15",
    color:"#fff",
    height:"45px",
    "& p": {
      paddingTop:'14px', 
    },
    [theme.breakpoints.down('sm')]: {
      height:"100%",
    },
  },
  topHeaderInner:{
    marginLeft: '50px',
  },
  toolBar:{
    backgroundColor:"#fff",
    paddingTop: "7px",
    minHeight:"60px",
    [theme.breakpoints.down('sm')]: {
      minHeight:"45px",
    },
  },
  headerNav:{
    marginLeft: '30px',
    "& button": {
      color:'#000',
      fontSize:"12",
      padding:'10px',
    }
  },
  footerNav:{
    marginLeft: '280px',
    "& button": {
      color:'#fff',
      fontSize:"16px",
      paddingTop:'13px',
      paddingLeft:'5px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop:"0px",
      marginLeft: '0px'
    },
  },
  socialIcons:{
    marginLeft: 'auto',
    "& img": {
      padding:'5px'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color:"#f58220",
    paddingTop:"6px"
  }, 
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  }, 
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  hr: {
    borderTop: '1px solid #ccc',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
    },
  },
  linkHeading: {
    color: '#9d9d9d',
    paddingTop: '15px'
  },
  linkHeadingMobile: {
    color: '#9d9d9d',
    paddingTop: '15px',
    textAlign: 'center'
  },

  /** Quick Link Icons */
  SCFIco_Bt: {
    backgroundImage: `url(${linkIcon1})`,
    backgroundColor: '#e4e4e4',
    width: '113px',
    height: '113px',
    border: 'none !important',
    textDecoration: 'none !important',
    display: 'inline-block',
    fontSize: '18px',
    borderRadius: '60px 60px 0 60px !important',
    color: '#8a8a8a',
    margin: '25px',
    "&:hover": {
      backgroundImage: `url(${linkIconHover1})`,
      backgroundColor: '#f58220',
      width: '113px',
      height: '113px',
      border: 'none',
      color: '#f58220',
    },
    "&:hover span": {
      color: '#f58220',
    }
  },
  SCFIco: {
    position: 'absolute',
    top: '125px',
    left: '15%',
    fontSize: '15px',
    color: '#7b7979',
    width: '100px'
  },

  IBIco_Bt: {
    backgroundImage: `url(${linkIcon3})`,
    backgroundColor: '#e4e4e4',
    width: '125px',
    height: '113px',
    border: 'none !important',
    textDecoration: 'none !important',
    display: 'inline-block',
    fontSize: '18px',
    borderRadius: '74px 75px 0 68px !important',
    color: '#8a8a8a',
    boxShadow: 'none !important',
    margin: '25px',
    "&:hover": {
      backgroundImage: `url(${linkIconHover3})`,
      backgroundColor: '#f58220',
      width: '125px',
      height: '113px',
      border: 'none',
      color: '#f58220',
      boxShadow: 'none !important'
    },
    "&:hover span": {
      color: '#f58220',
      outline: 'none !important'
    }
  },
  IBIco: {
    position: 'absolute',
    top: '125px',
    left: '15%',
    fontSize: '15px',
    color: '#7b7979',
    width: '100px'
  },

  PPIco_Bt: {
    backgroundImage: `url(${linkIcon2})`,
    backgroundColor: '#e4e4e4',
    width: '125px',
    height: '113px',
    border: 'none !important',
    textDecoration: 'none !important',
    display: 'inline-block',
    fontSize: '18px',
    borderRadius: '60px 60px 0 60px !important',
    color: '#8a8a8a',
    boxShadow: 'none !important',
    margin: '25px',
    "&:hover": {
      backgroundImage: `url(${linkIconHover2})`,
      backgroundColor: '#f58220',
      width: '125px',
      height: '113px',
      border: 'none',
      color: '#f58220',
      boxShadow: 'none !important'
    },
    "&:hover span": {
      color: '#f58220',
      outline: 'none !important'
    }
  },
  PPIco: {
    position: 'absolute',
    top: '125px',
    left: '15%',
    fontSize: '15px',
    color: '#7b7979',
    width: '100px'
  },

  VOIco_Bt: {
    backgroundImage: `url(${linkIcon4})`,
    backgroundColor: '#e4e4e4',
    width: '125px',
    height: '113px',
    border: 'none !important',
    textDecoration: 'none !important',
    display: 'inline-block',
    fontSize: '18px',
    borderRadius: '60px 60px 0 60px !important',
    color: '#8a8a8a',
    boxShadow: 'none !important',
    margin: '25px',
    "&:hover": {
      backgroundImage: `url(${linkIconHover4})`,
      backgroundColor: '#f58220',
      width: '125px',
      height: '113px',
      border: 'none',
      color: '#f58220',
      boxShadow: 'none !important'
    },
    "&:hover span": {
      color: '#f58220',
      outline: 'none !important'
    }
  },
  VOIco: {
    position: 'absolute',
    top: '125px',
    left: '17%',
    fontSize: '15px',
    color: '#7b7979',
    width: '100px'
  },

  UTIco_Bt: {
    backgroundImage: `url(${linkIcon5})`,
    backgroundColor: '#e4e4e4',
    width: '113px',
    height: '113px',
    border: 'none !important',
    textDecoration: 'none !important',
    display: 'inline-block',
    fontSize: '18px',
    borderRadius: '60px 60px 0 60px !important',
    color: '#8a8a8a',
    boxShadow: 'none !important',
    margin: '25px',
    "&:hover": {
      backgroundImage: `url(${linkIconHover5})`,
      backgroundColor: '#f58220',
      width: '113px',
      height: '113px',
      border: 'none',
      color: '#f58220',
      boxShadow: 'none !important'
    },
    "&:hover span": {
      color: '#f58220',
      outline: 'none !important'
    }
  },
  UTIco: {
    position: 'absolute',
    top: '125px',
    left: '22%',
    fontSize: '15px',
    color: '#7b7979',
    width: '80px'
  },

  playIconBann:{
    width: "156px",
    height: "156px",
    backgroundImage: `url(${playBan})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "50%",
    left: "50%",
    cursor: "pointer",
    transform: "translate(10%, -50%)",
    webkitTransform: "translate(10%, -50%)",
    [theme.breakpoints.down('sm')]: {
        bottom: "10px",
        top:"auto",
        left: "50%",
        transform: "translate(-50%, 0%)",
        webkitTransform: "translate(-50%, 0%)", 
    },
    [theme.breakpoints.down('xs')]: {
      transform: "translate(-30%, 40%)",
      webkitTransform: "translate(-30%, 40%)",
      backgroundSize: "60% 60%",
  },
  }
}));
