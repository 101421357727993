import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../store/actions'
import {connect} from "react-redux";
import {onChangeInput, validateEmail, validateInputs, validatePassword} from "../../../services/utils";
import {useStyles} from './styles'
import Loader from "../../../components/loading";
import Alert from "../../../components/alert";
import {finance} from "../../../assets/images/img/index";
import InputRow from "../../../components/inputComponents/inputRow";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import './style.scss'

const SignIn = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    values: {
      email: "",
      // email: "",
      // email: "manager@manager.com",
      // email: "finance@finance.com",
      // email: "commercial@commercial.com",
      // email: "media@media.com",
      // password: "1234567",
      password: "",
      showPassword: false
    },
    errors: {
      email: "",
      password: "",
    },
  });
  useEffect(() => {

    const {user, error, success, loading} = props.authReducer;
    if (props.authReducer.error) {
      setTimeout(() => {
        props.errorHandlerSuccess();
      }, 2000);
    } else {
      if (!loading && success && props.authReducer.user) {
        props.history.push("/dashboard");
        props.errorHandlerSuccess();
      }
    }
  }, [props.authReducer.error, props.authReducer.user]);
  const {values, errors} = state;

  const onLogin = () => {
    let {email, password} = values;
    const data = {email, password}
    let recordedErrors = validateInputs(data);
    if (Object.keys(recordedErrors).length > 0) {
      console.log(recordedErrors)
      setState((prevState) => ({
        ...prevState,
        errors: recordedErrors,
      }));
    } else {
      let recordedErrors = {}
      // if (!validateEmail(email)) {
      //   recordedErrors.email = 'Invalid Email';
      // }
      let passwordCheck = validatePassword(password);
      if (passwordCheck) {
        recordedErrors.password = passwordCheck;
      }
      if (Object.keys(recordedErrors).length > 0) {
        setState((prevState) => ({
          ...prevState,
          errors: recordedErrors,
        }));
      } else {
        const data = {email, password};
        props.onLoginHandler(data);
      }
    }
  };

  return (
    <Grid container component="main" className={classes.root}>

      {/*<video width="320" height="240" controls>*/}
      {/*  <source src="http://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4"/>*/}
      {/*    <source src="movie.ogg" type="video/ogg"/>*/}
      {/*</video>*/}
      {/*<CssBaseline/>*/}
      <Grid container xs={12} lg={6} sm={6} md={7} direction={'column'} className={'login-page-image-sec'}>
        {/* <Grid className='title-container'>
          <p>Ufone Hashtag</p>
        </Grid> */}
        <Grid container className='img-container'>
          <h3>Ufone Hashtag</h3>
          {/* <h3>Reporting <br/> Platform</h3> */}
          <img src={finance} />
        </Grid>
      </Grid>
      <Grid container alignItems='center' xs={12} sm={6} md={5}lg={6}  component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}> <LockOutlinedIcon/> </Avatar>
          <Typography component="h1" variant="h5"> Sign in </Typography>
          {/*<input type='text' inputMode='url'/>*/}
          <Grid container xs={12} direction={'column'} alignItems={"center"} justify={"center"}>
            {((props.authReducer?.success || props.authReducer?.error) && !props.authReducer?.loading) &&
            <Alert type={props.authReducer.error ? 'error' : 'success'}
                   message={props.authReducer.error}/>
            }
            <form className={classes.form}>
              <InputRow
                types={[
                  {type: 'email', value: values.email, error: errors.email, label: 'email'},

                  {type: 'password', value: values.password, error: errors.password, label: 'password',},
                ]}
                onChangeInput={(value, key) => onChangeInput(value, key, state, setState)}
                numOfInputPerRow={1}
              />
              <div className='mt-20 mb-20 align-self-start ml-20'>
                {props.authReducer.loading ?
                  <Loader/> :
                  <PrimaryButton value={'sign in'} onClick={onLogin}/>
                }
              </div>
            </form>
          </Grid>
          {/*<Grid container>*/}
          {/*  <Grid item xs>*/}
          {/*    <Link href="#" variant="body2" className='logout-link font-14'>*/}
          {/*      Forgot password?*/}
          {/*    </Link>*/}
          {/*  </Grid>*/}
          {/*  <Grid item>*/}
          {/*    <Link href="/signup" variant="body2" className='logout-link font-14'>*/}
          {/*      {"Don't have an account? Sign Up"}*/}
          {/*    </Link>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = ({authReducer}) => {
  return {authReducer};
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLoginHandler: (data) => dispatch(actions.loginStart(data)),
    errorHandlerSuccess: () => dispatch(actions.loginErrorHandlerSuccess()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
