import * as actionTypes from "../actionTypes";
import {setUserDataInLocalStorage} from "../../services/utils";

export const loginStart = (data) => {
  return {
    type: actionTypes.LOGIN_START,
    payload: data,
  };
};
export const loginSuccess = (data) => {
  localStorage.setItem('token', data?.token);
  setUserDataInLocalStorage(data.user);
  let d = {user: data.user, message: 'successfully loggedIn '}
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: d,
  };
};
export const loginFailure = (error) => {

  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
  };
};
export const signupStart = (data) => {
  return {
    type: actionTypes.SIGNUP_START,
    payload: data,
  };
};
export const signupSuccess = (data, signup) => {
  let obj = {}
  if (signup) {
    setUserDataInLocalStorage(data.data.user);
    localStorage.setItem('token', data.data.token);
    obj = {user: data.data.user, success: data.message}
  } else {
    obj = {user: null, success: data.message}
  }
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    payload: obj,
  };
};
export const signupFailure = (error) => {
  return {
    type: actionTypes.SIGNUP_FAILURE,
    payload: error,
  };
};
export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
    payload: null,
  };
};

export const logoutSuccess = () => { 
  localStorage.removeItem('token');
  localStorage.removeItem('user');

  return {
    type: actionTypes.LOGOUT_SUCCESS,
    payload: null,
  };
};

export const loginErrorHandlerSuccess = () => {
  return {
    type: actionTypes.LOGIN_ERROR_HANDLER_SUCCESS,
    payload: null,
  };
};
