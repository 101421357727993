import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {all, fork, put, takeEvery} from "redux-saga/effects";
import AuthService from "../../services/auth.service";
import HttpService from "../../services/http.service";

function* loginStart(action) {
  try {
    const response = yield AuthService.login({
      email: action.payload.email,
      password: action.payload.password,
    });

    if (response.data && response.data.statusCode === 200) {
      HttpService.setToken(response.data.data.token);//set token in header
      let user = response?.data?.data?.user || {}
      let cp = response?.data?.data?.cp || {}
      let id = cp?.cp_id 
      if (user?.role === '3') {
        HttpService.setUser(id);// set userId and role in header
        localStorage.setItem('user', id);
      }
      yield put(actions.loginSuccess(response?.data?.data));
    } else {
      yield put(actions.loginFailure("Invalid Username/Password"));
    }
  } catch (err) { 
    let error = err?.response?.data?.message;

    if (!error) {
      error = err?.response?.message || "Network error";
    }
    yield put(actions.loginFailure(error));
  }
}

function* loginStartSaga() {
  yield takeEvery(actionsTypes.LOGIN_START, loginStart);
}

function* signUpStart(action) {
  try {
    let response = ''
    let payload = action.payload;
    let create = payload.create;
    delete payload.create
    if (create) {
      // create user flow
      delete payload.id
      response = yield AuthService.signup(payload);
    } else {
      // edit user flow
      response = yield AuthService.editUser(payload);
    }
    // const data = {
    //   email: action.payload.email,
    //   password: action.payload.password,
    //   name: action.payload.name,
    //   companyName: action.payload.companyName,
    //   role: action.payload.role,
    //   address: action.payload.address,
    //   mobile: action.payload.mobile,
    //   status: action.payload.status,
    // }


    if (response) {
      if (action.payload?.signup) {
        HttpService.setToken(response.data.data.token);
      }
      yield put(actions.signupSuccess(response?.data, action.payload?.signup));
    } else {
      yield put(actions.signupFailure('network error'));
    }
  } catch (err) { 
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* signupStartSaga() {
  yield takeEvery(actionsTypes.SIGNUP_START, signUpStart);
}

export function* authSaga() {
  yield all([fork(loginStartSaga), fork(signupStartSaga),]);
}
