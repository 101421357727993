import {all} from "redux-saga/effects";
import {authSaga} from "./authSagas";
import {dashboardSaga} from "./dashboardSagas";
import {userSaga} from "./userSagas";
import {auctionSaga} from "./auctionSaga";
import {paymentSaga} from "./paymentSaga";
export function* rootSaga(getState) {
  yield all([
    authSaga(),
    dashboardSaga(),
    userSaga(),
    auctionSaga(),
    paymentSaga()
  ]);
}
