export const dashboardPage = [
    {
        RevenueReport: 'Revenue report contains the revenue of each services against the mentioned time period.',
        label: "Revenue Report"
    },
    {
        LabelGrossRevenue: 'Label Gross Revenue is the total revenue generated from the provided content.',
        label: "Label Gross Revenue"
    },
    {TimeDuration: 'Time Duration is the period during which the content is monetized.', label: "Time Duration"},
    {OverAll: 'Overall is the service wise total Gross Revenue against the mentioned time duration.', label: "OverAll"},
    {Total: 'Month wise Gross Revenue generated on all services.', label: "Total"},
]

export const userHomePage = [
    {CPManager: 'Content Provider can be added or managed under this section.', label: "CP Manager"},
    {Finance: 'Internal members of finance can be added or managed under this section.', label: "Finance"},
    {CommercialTeam: 'For members responsible for dealing with the CPs directly.', label: "Commercial Team"},
    {MediaTeam: 'Internal members of media team can be added or managed under this section.', label: "Media Team"},
] 
export const creatUserPage = [
    {createUser: 'Create user by completing the profile.', label: "Create User"},
    {Role: 'Assign Role to the user.', label: "Role"},
]
    