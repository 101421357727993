import React, { useEffect, useState } from 'react'; 
import Typography from "@material-ui/core/Typography"; 
import { CustomSeparator } from "./index"; 
import { helpHandler } from "../store/actions";
import './styles.css'
import { useDispatch } from "react-redux";
import { dashboardPage } from "../services/userManual";

function Dashboard(props) {
 
  const dispatch = useDispatch();

  const helpClick = () => {
    dispatch(helpHandler({ open: true, data: dashboardPage }))
  }
  
 
  const [breadCrumbsList, setBreadCrumbsList] = React.useState([
    { text: 'Dashboard', url: '' }, { text: "Reports", url: '' },
  ]);
 

  return (
    <div className="driver-main-page">
      <CustomSeparator
        breadCrumbsList={breadCrumbsList}
        {...props}
        setBreadCrumbsList={setBreadCrumbsList}
        helpHandler={helpClick}
      />
      <div className="datatable-container1">
        <div className='table-title-container'>
          <Typography variant='h4' className='table-title'
     
          >Comming soon... </Typography>
          
        </div>
       
      </div>
    </div>
  );
}

export default Dashboard;
