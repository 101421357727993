import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainLayoutRoutes from "./mainRoutes";
import FrontLayoutRoutes from "./frontend/frontRoutes";
import SignIn from "../container/auth/signIn/signIn";
import {Confirmation, HelpModal} from "../components";
 
import Dashboard from "../container/homePage";
const Home =  React.lazy(() => import('../container/frontend/home'));
const ThankYou =  React.lazy(() => import('../container/frontend/thankyou'));
const Payment =  React.lazy(() => import('../container/frontend/payment'));
const PaymentResponse =  React.lazy(() => import('../container/frontend/payment/response'));
const Users = React.lazy(() => import('../container/users'));
const CreateUser = React.lazy(() => import('../container/users/createUser/createUser'));

function Routes() {

  return (
    <Router>
      <Switch>
        <FrontLayoutRoutes exact path="/"  routeName={'home'} render={matchprops => (<Home {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/thankYou"  routeName={'thankYou'} render={matchprops => (<ThankYou {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/makePayment/:id"  routeName={'payment'} render={matchprops => (<Payment {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/paymentResponse/:id"  routeName={'paymentResponse'} render={matchprops => (<PaymentResponse {...matchprops} />)} />

        <Route path="/login" exact component={SignIn}/>
        <MainLayoutRoutes exact path="/dashboard" render={matchprops => (<Dashboard {...matchprops} />)}
                          routeName={'dashboard'}/>
        <MainLayoutRoutes exact path="/users" render={matchprops => (<Users {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/:id" render={matchprops => (<CreateUser {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/createNew" render={matchprops => (<CreateUser {...matchprops} />)}/>

      </Switch>
      <Confirmation/>
      <HelpModal/>
    </Router>
  )
    ;
}

export default Routes;