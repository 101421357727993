import React from 'react';

function Index(props) {
	const {text}=props;
	return (
		<div className='nothing-to-show-container'>
			<div className='nothing-to-show-text'>{text}</div>
		</div>
	);
}

export default Index;
