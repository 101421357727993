// Users Action Types
export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";
export const SET_USER_SERVICES_LIST = "USER_SERVICES_LIST";
export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

// Auction Action Types
export const GET_AUCTION_LIST_START = "GET_AUCTION_LIST_START";
export const GET_AUCTION_LIST_SUCCESS = "GET_AUCTION_LIST_SUCCESS";
export const GET_AUCTION_LIST_FAILURE = "GET_AUCTION_LIST_FAILURE";
export const GET_AUCTION_DATA_START = "GET_AUCTION_DATA_START";
export const GET_AUCTION_DATA_SUCCESS = "GET_AUCTION_DATA_SUCCESS";
export const GET_AUCTION_DATA_FAILURE = "GET_AUCTION_DATA_ERROR";

export const SEND_OTP_START = "SEND_OTP_START";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_ERROR";

export const GET_PAYMENT_DATA_START = "GET_PAYMENT_DATA_START";
export const GET_PAYMENT_DATA_SUCCESS = "GET_PAYMENT_DATA_SUCCESS";
export const GET_PAYMENT_DATA_FAILURE = "GET_PAYMENT_DATA_ERROR";

export const CREATE_AUCTION_START = "CREATE_AUCTION_START";
export const CREATE_AUCTION_SUCCESS = "CREATE_AUCTION_SUCCESS";
export const CREATE_AUCTION_FAILURE = "CREATE_AUCTION_ERROR";


export const MESSAGE_HANDLER = "MESSAGE_HANDLER"; 

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_ERROR_HANDLER_SUCCESS = "LOGIN_ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const HELP_HANDLER = "HELP_HANDLER";