import React, {useEffect, useState} from 'react';  
import {useStyles} from "./styles"; 
import {generalStyles} from "../general/general";
import Container from '@material-ui/core/Container'; 
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid'; 
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Carousel from 'react-material-ui-carousel'
export default function Header() { 
  const classes = useStyles();
  const generalClasses = generalStyles();

  const footerLinks = [{ 
      title: "Prepaid |", 
      link:"https://www.ufone.com/prepaid/", 
    },{ 
      title: " Postpay |", 
      link:"https://www.ufone.com/postpay/", 
    },{ 
      title: " VAS |", 
      link:"https://www.ufone.com/vas/", 
    },{ 
      title: " Devices |", 
      link:"https://www.ufone.com/offer-devices/", 
    },{ 
      title: " International |", 
      link:"https://www.ufone.com/international/", 
    },{ 
      title: " My Ufone |", 
      link:"https://www.ufone.com/selfcare/app/login/login.php", 
    },{ 
      title: " Press Center |", 
      link:"https://www.ufone.com/press-center/", 
    },{ 
      title: " DIRBS", 
      link:"https://www.ufone.com/device-Identification/", 
    }
  ];

  const quickLinks = [
    {
      title: 'Super Card Family',
      MainClass: classes.SCFIco_Bt,
      spanClass: classes.SCFIco,
      link: 'https://www.ufone.com/prepaid/super-card-family'
    },
    {
      title: 'Internet Bundles',
      MainClass: classes.IBIco_Bt,
      spanClass: classes.IBIco,
      link: 'https://www.ufone.com/internet-offers'
    },
    {
      title: 'Postpay',
      MainClass: classes.PPIco_Bt,
      spanClass: classes.PPIco,
      link: 'https://www.ufone.com/postpay/'
    },
    {
      title: 'Coverage',
      MainClass: classes.IBIco_Bt,
      spanClass: classes.IBIco,
      link: 'https://www.ufone.com/selfcare/app/support/coverage.php'
    },
    {
      title: 'Self Care',
      MainClass: classes.VOIco_Bt,
      spanClass: classes.VOIco,
      link: 'https://www.ufone.com/selfcare/app/login/login.php'
    },
    {
      title: 'Buy Sim',
      MainClass: classes.UTIco_Bt,
      spanClass: classes.UTIco,
      link: 'https://www.ufone.com/selfcare/app/buy-sim/buysim.php'
    },
    {
      title: 'Devices',
      MainClass: classes.UTIco_Bt,
      spanClass: classes.UTIco,
      link: 'https://www.ufone.com/offer-devices'
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
 
  const openInNewTab = url => { 
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return ( 
    <div className={generalClasses.root}>
      <Container fixed>
        <div className={classes.hr}>
          <Typography className={classes.linkHeading} variant="h3" gutterBottom>Quick Links</Typography>
          <Box sx={{ display: { xs: 'none', sm:'none', md:'block' } }}>
            <Grid container spacing={0}>
              <div className={`${generalClasses.centerALign} ${generalClasses.marginBottom90}`}>
                {quickLinks.map((item, index) =>(
                  <Link key={index} onClick={() => openInNewTab(item.link)} component="button" variant="body2" className={item.MainClass}>
                    <span className={item.spanClass}>{item.title}</span>
                  </Link>
                ))}
              </div>
            </Grid> 
          </Box>
            
           <Box sx={{ display: { xs: 'block', sm:'block', md:'none' } }}>
              <Carousel
                NextIcon={<KeyboardArrowRight/>}
                PrevIcon={<KeyboardArrowLeft/> }
                indicators={false}
                navButtonsAlwaysVisible={true}
              >
                {quickLinks.map((item, index) => (
                  <div className={`${generalClasses.centerALign} ${generalClasses.paddingBottom60}`} key={index}> 
                    <Link onClick={() => openInNewTab(item.link)} component="button" variant="body2" className={item.MainClass}>
                      <span className={item.spanClass}>{item.title}</span>
                    </Link>  
                  </div>
                ))} 
               </Carousel>
          </Box> 
        </div>  
      </Container>

      <div  className={classes.footer}>
        <Container fixed>

          <div className={generalClasses.displayFlex}>
            <Typography variant='body2' >&copy; {new Date().getFullYear()} UFONE. All rights reserved</Typography>
            <Box sx={{ display: { xs: 'block', md:'block' } }} > 
              <div className={classes.footerNav}>
                {footerLinks.map((item, index) => (
                  <Link onClick={() => openInNewTab(item.link)} key={index} component="button" variant="body2">
                    {item.title}
                  </Link>
                ))} 
              </div> 
            </Box>
          </div>

        </Container>
      </div> 
    </div>
  );
}
