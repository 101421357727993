import userIcon from './userIcon.png';
import boxBid from './boxbid.jpg';
import playBan from './playBan.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from "@material-ui/icons/Edit";
import FavoriteIcon from '@material-ui/icons/Favorite';
import LineStyleOutlinedIcon from '@material-ui/icons/LineStyleOutlined';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import MovieCreationOutlinedIcon from '@material-ui/icons/MovieCreationOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import PublicIcon from '@material-ui/icons/Public';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import linkIcon1 from './img1.png';
import linkIcon2 from './img3.png';
import linkIcon3 from './img4.png';
import linkIcon4 from './img5.png';
import linkIcon5 from './buysim.png';
import linkIconHover1 from './hover1.png';
import linkIconHover2 from './hover3.png';
import linkIconHover3 from './hover4.png';
import linkIconHover4 from './hover5.png';
import linkIconHover5 from './buysim-hover.png';

const Download = GetAppIcon;
const UploadFile = CloudUploadOutlinedIcon;
const NextIcon = NavigateNextIcon;
const SkipIcon = SkipNextIcon;
const BackIcon = ArrowBackIosIcon
const VideoIcon = OndemandVideoOutlinedIcon
const AudioIcon = AudiotrackOutlinedIcon
const CountryIcon = PublicIcon
export {
  userIcon,boxBid,playBan, 
  Download,
  UploadFile,
  ClearIcon,
  NextIcon,
  SkipIcon,
  BackIcon,
  DoneIcon,
  EditIcon,
  LineStyleOutlinedIcon,
  PersonPinIcon,
  MovieCreationOutlinedIcon,
  AccountBalanceWalletOutlinedIcon,
  FavoriteIcon,
  VideoIcon,
  AudioIcon,
  CountryIcon,
  ExpandMoreIcon,
  ExpandLessIcon,

  linkIcon1,
  linkIcon2,
  linkIcon3,
  linkIcon4,
  linkIcon5,

  linkIconHover1,
  linkIconHover2,
  linkIconHover3,
  linkIconHover4,
  linkIconHover5
}
