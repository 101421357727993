import {combineReducers} from "redux";
import {authReducer} from "./auth";
import {dashboardReducer} from "./dashboard";
import {usersReducer} from "./usersReudcer"; 
import {auctionReducer} from "./auctionReducer"; 
import {paymentReducer} from "./paymentReducer"; 
import {confirmationReducer} from './confirmationReducer'; 
import {helpReducer} from './helpReducer'; 
export const rootReducer = combineReducers({
  authReducer, dashboardReducer, usersReducer, auctionReducer, paymentReducer, confirmationReducer, helpReducer
});
