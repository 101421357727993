import * as actions from "../actions"; 
import {put, takeEvery, all, fork} from "redux-saga/effects";
import PaymentService from '../../services/payment.service'
import * as actionsTypes from "../actionTypes";

function* getPaymentData(action) {
  try { 
    if (action.payload) {
      const transactionId = action.payload?.transactionId;  
      const response = yield PaymentService.data(transactionId);  
      if (response?.data.data.isSuccess) {
        yield put(actions.getPaymentDataSuccess(response.data));
      } else {
        yield put(actions.getPaymentDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    
    yield put(actions.getPaymentDataFailure(error));
  }
}

function* getPaymentDataSaga() {
  yield takeEvery(actionsTypes.GET_PAYMENT_DATA_START, getPaymentData);
}

export function* paymentSaga() {
  yield all([fork(getPaymentDataSaga)]);
}
