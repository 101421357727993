import HttpService from "./http.service";

class AuctionService extends HttpService {
    list = (params) => this.get("auction/search", params);

    sendOtp = (params) => this.post(`otp/send?msisdn=${params}`);

    verifyOtp = (data) => this.post(`otp/verify?msisdn=${data.msisdn}&otp_code=${data.otp_code}`);

    createAuction = (data) => this.post(`auction?msisdn=${data.msisdn}&tagName=${data.tagName}&bidPrice=${data.bidPrice}`);
}

export default new AuctionService();